import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import { Link } from "gatsby";
import { Logo } from "components/Logo";

export function Header({ overlay = false }) {
  const [navOpen, setNavOpen] = useState(false);

  const [specialDropdownOpen, setSpecialDropdownOpen] = useState(false);
  const [eventsDropdownOpen, setEventsDropdownOpen] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  return (
    <nav
      id="header"
      className={`${
        overlay ? "absolute" : "relative"
      } flex site-header w-full z-30 top-0`}
      style={
        overlay && !navOpen
          ? { background: "transparent", "--on-site-header": "#fff" }
          : null
      }
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-6">
        <div className="flex items-center">
          <Link activeClassName="active" to="/">
            <Logo className="w-16 lg:w-24 h-auto" />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            id="nav-toggle"
            onClick={() => setNavOpen(!navOpen)}
            className="flex items-center p-1 text-white hover:text-white focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          style={
            navOpen
              ? {
                  display: "flex",
                  top: "var(--site-header-height)",
                  background: "var(--site-header)",
                  left: 0,
                }
              : {}
          }
          className="w-full absolute lg:relative flex-col lg:flex-row lg:flex lg:items-center lg:w-auto hidden lg:mt-0 p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <Link
                className="inline-block py-2 px-4 font-bold no-underline"
                to={`/tickets`}
                activeClassName="active"
              >
                TICKETS
              </Link>
            </li>
            <li className="mr-3 relative">
              <a
                onClick={() => {
                  setEventsDropdownOpen(!eventsDropdownOpen);
                }}
                className="cursor-pointer ali py-2 px-4 font-bold no-underline text-black"
              >
                <span>EVENTS</span>
                <svg
                  className="w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={{
                    transform: eventsDropdownOpen
                      ? "translateY(-1px) rotateX(180deg)"
                      : "translateY(-1px)",
                  }}
                >
                  <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </a>
              {eventsDropdownOpen && (
                <ul className="lg:absolute uppercase bg-white text-black rounded-lg lg:shadow-lg lg:py-4 px-8 lg:px-4 whitespace-nowrap">
                  <li className="py-1">
                    <Link
                      activeClassName="active"
                      to="/tickets/bobbys-disco-club"
                    >
                      Bobby's Disco Club
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link activeClassName="active" to="/tickets/maad-city">
                      MAAD CITY
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link activeClassName="active" to="/tickets/full-fat">
                      FULL FAT
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link activeClassName="active" to="/tickets/somethin-good">
                      Somethin’ Good
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link
                      activeClassName="active"
                      to="/special-events/better-days-festival"
                    >
                      Better Days Festival
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link activeClassName="active" to="/tickets/better-nights">
                      Better Nights
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            {/* <li className="mr-3 relative">
              <a
                onClick={() => {
                  setSpecialDropdownOpen(!specialDropdownOpen);
                }}
                className="cursor-pointer ali py-2 px-4 font-bold no-underline text-black"
              >
                <span>SPECIAL EVENTS</span>
                <svg
                  className="w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={{
                    transform: specialDropdownOpen
                      ? "translateY(-1px) rotateX(180deg)"
                      : "translateY(-1px)",
                  }}
                >
                  <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </a>
              {specialDropdownOpen && (
                <ul className="lg:absolute bg-white text-black rounded-lg lg:shadow-lg lg:py-4 px-8 lg:px-4 whitespace-nowrap">
                  <li className="py-1">
                    <Link
                      activeClassName="active"
                      to="/special-events/better-days-festival"
                    >
                      BETTER DAYS FESTIVAL
                    </Link>
                  </li>

                  <li className="py-1">
                    <Link
                      activeClassName="active"
                      to="/special-events/the-secret-social"
                    >
                      THE SECRET SOCIAL
                    </Link>
                  </li>
                  <li className="py-1">
                    <Link
                      activeClassName="active"
                      to="/special-events/its-coming-home"
                    >
                      IT'S COMING HOME
                    </Link>
                  </li>
                </ul>
              )}
            </li> */}
            <li className="mr-3">
              <Link
                className="inline-block py-2 px-4 font-bold no-underline"
                to={`/blog`}
                activeClassName="active"
              >
                BLOG
              </Link>
            </li>
            <li className="mr-3">
              <Link
                className="inline-block py-2 px-4 font-bold no-underline"
                to={`/student-jobs`}
                activeClassName="active"
              >
                STUDENT JOBS
              </Link>
            </li>
            <li className="mr-3">
              <Link
                className="inline-block py-2 px-4 font-bold no-underline"
                to={`/contact`}
                activeClassName="active"
              >
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function Dropdown() {
  return <div>aa</div>;
}
